<template>
  <vs-popup
    class="cust-vspopup-w500 cust-h-mob-pop"
    :title="title"
    :active.sync="isActiveLocal"
    :fullscreen="fullscreen"
  >
    <div class="mx-4" :class="{ 'flex flex-col': !noteView }">
      <div class="w-full" v-if="!noteView">
        <div class="overflow-y-auto">
          <p class="h2-class mr-2 text-start mb-4 ">Money Market</p>
          <MoneyMarketDayData :showDate="showDate" />
        </div>
      </div>
      <div :class="{ 'md:flex lg:flex': !noteView }">
        <div
          class="md:w-1/2 lg:w-1/2 md:mr-4 lg:mr-4 mob-mb2rem"
          v-if="!noteView"
        >
          <p class="h2-class mr-2 text-start mb-4 " v-if="fxEvents.title">FX</p>
          <div
            id="div-with-loading"
            class="vs-con-loading__container w-full h-8"
            v-if="isLoading && !isError"
          ></div>
          <div class="w-full h-8 flex justify-center" v-if="isError">
            <div class="text-center">
              <feather-icon
                icon="RotateCcwIcon"
                svgClasses="w-5 h-5 m-2"
                class="cursor-pointer hover:text-primary rounded-full w-8 h-8"
                @click="handleReload"
              ></feather-icon>

              <p>Reload</p>
            </div>
          </div>
          <div
            v-if="!isLoading && !isError && fxEvents.title"
            draggable="false"
            :class="`${fxEvents.classes} text-sm p-3  cust-w-bord`"
            :title="stripHTML(fxEvents.title)"
            v-html="fxEvents.title"
          ></div>
        </div>
        <DayNote
          :class="{ 'md:w-1/2 lg:w-1/2  md:ml-4 lg:ml-4': !noteView }"
          :note="note"
          :date="showDate"
          @noteEdited="handleNoteEdit"
          @noteDeleted="handleNoteDelete"
          v-if="isLoggedIn"
        />
      </div>
    </div>
  </vs-popup>
</template>

<script>
import calendarMixin from "@/Client/Kotak/mixins/calendarMixin";
import fxMixin from "@/Client/Kotak/mixins/fxMixin";

import DayNote from "./DayNote";
import MoneyMarketDayData from "./MoneyMarketDayData";

export default {
  mixins: [calendarMixin, fxMixin],
  props: {
    params: {
      type: Object,
      default: () => {}
    },
    isActive: {
      type: Boolean,
      required: true
    },
    noteView: {
      type: Boolean,
      default: false
    },
    fullscreen: {
      type: Boolean,
      default: false
    },
    isLoggedIn: {
      type: Boolean,
      default: false
    }
  },
  components: {
    DayNote,
    MoneyMarketDayData
  },
  watch: {
    isActive(val) {
      if (!val) {
        this.resetParams();
      } else {
        this.setParams();
      }
    }
  },
  computed: {
    isActiveLocal: {
      get() {
        return this.isActive;
      },
      set(val) {
        if (!val) {
          this.handleClose();
        }
      }
    },
    title() {
      return `${
        this.days[
          this.dayOfDate(
            this.showDate.getFullYear(),
            this.showDate.getMonth(),
            this.day
          )
        ]
      }, ${this.months[this.month]} ${this.day} ${this.year}`;
    }
  },
  data() {
    return {
      showDate: new Date(),
      value: {},
      month: new Date().getMonth(),
      year: new Date().getFullYear(),
      day: new Date().getDate(),
      note: {},
      isLoading: true,
      fxEvents: {},
      isError: false
    };
  },
  methods: {
    handleClose() {
      this.$emit("closePopup");
    },
    setParams() {
      const param = this.params;

      if (param.date) {
        this.showDate = param.date;
        this.getData(
          this.getCurrentDate(param.date),
          this.getCurrentDate(param.date)
        );
      }
      if (param.events) {
        this.value = param.events[0];
      }
      if (param.year) {
        this.year = param.year;
      }
      if (param.month) {
        this.month = param.month;
      }
      if (param.day) {
        this.day = param.day;
      }
      if (param.note) {
        this.note = param.note;
      }
    },
    resetParams() {
      this.showDate = new Date();
      this.value = {};
      this.month = new Date().getMonth();
      this.year = new Date().getFullYear();
      this.day = new Date().getDate();
      this.note = {};
      this.fxEvents = {};
      this.isError = false;
    },
    handleReload() {
      this.isError = false;
      this.getData(
        this.getCurrentDate(this.showDate),
        this.getCurrentDate(this.showDate)
      );
    },
    handleNoteDelete() {
      if (!this.noteView) return;
      this.handleClose();
    }
  },
  created() {
    this.setParams();
  }
};
</script>

<style lang="scss">
@import "@/Core/assets/scss/vuexy/apps/simple-calendar.scss";
</style>

<!--

<table class="table-auto border-l-b-r">
            <thead>
              <tr>
                <th
                  class="popup-header-table rounded-lg"
                  rowspan="2"
                  colspan="3"
                >
                  Money Market
                </th>
                <th class="popup-header-table rounded-lg" rowspan="2">
                  International Data
                </th>
                <th class="popup-header-table rounded-lg" colspan="15">
                  Market Last Year
                </th>
              </tr>
              <tr>
                <th
                  class="bg-primary-trans text-black text-center fsize10"
                  colspan="4"
                >
                  Currency Against
                </th>
                <th
                  class="bg-primary-trans text-black text-center fsize10"
                  colspan="5"
                >
                  Interest Rates %
                </th>
                <th
                  class="bg-primary-trans text-black text-center fsize10"
                  colspan="6"
                >
                  Others
                </th>
              </tr>
              <tr>
                <th class="bg-primary-trans text-black text-center fsize10">
                  Inflow
                </th>
                <th class="bg-primary-trans text-black text-center fsize10">
                  Amount
                </th>
                <th class="bg-primary-trans text-black text-center fsize10">
                  Outflow
                </th>
                <th
                  class="bg-primary-trans text-black text-center fsize10"
                ></th>
                <th class="bg-primary-trans text-black text-center fsize10">
                  EUR
                </th>
                <th class="bg-primary-trans text-black text-center fsize10">
                  JPY
                </th>
                <th class="bg-primary-trans text-black text-center fsize10">
                  GER
                </th>
                <th class="bg-primary-trans text-black text-center fsize10">
                  INR
                </th>
                <th class="bg-primary-trans text-black text-center fsize10">
                  NSE MIBOR
                </th>
                <th class="bg-primary-trans text-black text-center fsize10">
                  G-Sec 10-Yr
                </th>
                <th class="bg-primary-trans text-black text-center fsize10">
                  OIS 5-Yr
                </th>
                <th class="bg-primary-trans text-black text-center fsize10">
                  CorpBand AAA 5-Yr
                </th>
                <th class="bg-primary-trans text-black text-center fsize10">
                  1-Yr INR fwd (%)
                </th>
                <th class="bg-primary-trans text-black text-center fsize10">
                  US Libor 6-months
                </th>
                <th class="bg-primary-trans text-black text-center fsize10">
                  Brent Oil($/bbl)
                </th>
                <th class="bg-primary-trans text-black text-center fsize10">
                  Spot Gold ($/oz)
                </th>
                <th class="bg-primary-trans text-black text-center fsize10">
                  CPI Inflation
                </th>
                <th class="bg-primary-trans text-black text-center fsize10">
                  SENSEX
                </th>
                <th class="bg-primary-trans text-black text-center fsize10">
                  System Liquidity
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="border-r text-center fsize10">SDL Coupons</td>
                <td class="border-r text-center fsize10">984</td>
                <td class="border-r"></td>
                <td class="border-r text-center fsize10">
                  US: Dec Manufacturing PMI EZ: Dec Manufacturing PMI UK: Dec
                  Manufacturing PMI; Nov Mortgage approvals JP: Dec
                  Manufacturing PMI, Vehicles sales IN: Dec Manufacturing PMI
                </td>
                <td class="border-r text-center fsize10">1.12</td>
                <td class="border-r text-center fsize10">108.65</td>
                <td class="border-r text-center fsize10">1.32</td>
                <td class="border-r text-center fsize10">71.23</td>
                <td class="border-r text-center fsize10">5.25</td>
                <td class="border-r text-center fsize10">6.51</td>
                <td class="border-r text-center fsize10">5.52</td>
                <td class="border-r text-center fsize10">7.22</td>
                <td class="border-r text-center fsize10">4.26</td>
                <td class="border-r text-center fsize10">-</td>
                <td class="border-r text-center fsize10">-</td>
                <td class="border-r text-center fsize10">1517</td>
                <td class="border-r text-center fsize10">-</td>
                <td class="border-r text-center fsize10">41306</td>
                <td class="border-r text-center fsize10">3000</td>
              </tr>
            </tbody>
          </table>

-->

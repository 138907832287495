export default [
  {
    quote:
      "Experience is simply the name we give our mistakes",
    author: "Oscar Wilde"
  },
  {
    quote: "You do not succeed without obsession.",
    author: "Rakesh Jhunjhunwala"
  },
  {
    quote: "Doing nothing is very hard to do. You never know when you’re finished",
    author: "Leslie Nielsen"
  },
  {
    quote: "There are two ways to conquer and enslave a nation. One is by the sword. The other is by debt",
    author: "John Adams"
  },
  {
    quote:
      "Nothing is so permanent as a temporary government program",
    author: "Milton Friedman"
  },
  {
    quote: "Advice is what we ask for when we already know the answer but wish we didn’t",
    author: "Erica Jong"
  },
  {
    quote: "If you can’t explain it to a six year old, you don’t understand it yourself",
    author: "Albert Einstein"
  },
  {
    quote: "Failure is not fatal, but failure to change might be",
    author: "John Wooden"
  },
  {
    quote: "Success isn’t permanent, and failure isn’t fatal",
    author: "Mike Ditka"
  },
  {
    quote:
      "Nothing so weakens a government as inflation.",
    author: "J.K. Galbraith"
  },
  {
    quote: "No nation was ever ruined by trade.",
    author: "Benjamin Franklin"
  },
  {
    quote:
      "The purpose of studying economics is not to acquire a set of ready-made answers to economic questions, but to learn how to avoid being deceived by economists.",
    author: "Joan Robinson"
  },
  {
    quote: "Any policy maker’s tool kit must have enough room for fiscal policies because they are of paramount importance",
    author: "Gita Gopinath"
  },
  {
    quote: "The curious mind embraces science; the gifted and sensitive, the arts; the practical, business; the leftover becomes an economist",
    author: "Nassim Nicholas Taleb"
  },
  {
    quote:
      "It's not whether you're right or wrong that's important, but how much money you make when you're right and how much you lose when you're wrong.",
    author: "George Soros"
  },
  {
    quote: "The biggest risk of all is not taking one.",
    author: "Mellody Hobson"
  },
  {
    quote:
      "The most contrarian thing of all is not to oppose the crowd but to think for yourself.",
    author: "Peter Thiel"
  },
  {
    quote: "Far more money has been lost by investors trying to anticipate corrections, than lost in the corrections themselves.",
    author: "Peter Lynch"
  },
  {
    quote: "You can see the computer age everywhere but in the productivity statistics.",
    author: "Robert Solow"
  },
  {
    quote: "An economist is an expert who will know tomorrow why the things he predicted yesterday didn't happen today.",
    author: "Lawrence J. Peter"
  },
  {
    quote: "Free trade, one of the greatest blessings which a government can confer on a people, is in almost every country unpopular.",
    author: "Lord Thomas Macauley"
  },
  {
    quote: "Protectionism is the institutionalization of economic failure.",
    author: "Edward Heath"
  },
  {
    quote:
      "Neither a state nor a bank ever have had unrestricted power of issuing paper money without abusing that power.",
    author: "David Ricardo"
  },
  {
    quote: "We have one asset, and that's people.",
    author: "Sylvia Ostry"
  }
];

<template>
  <div class="vx-card no-scroll-content calendar-header">
    <div class="flex flex-col mm-body px-2">
      <div class=" ">
        <CalendarHeader>
          <template slot="views"
            ><div>
              <SelectMonth
                :showDate="showDate"
                @monthChanged="setMonthFromSelect"
                moneyMarket
              /></div
          ></template>
          <template slot="month">
            <div class="mt-3">
              <!-- <div class="flex justify-around">
                          <feather-icon
                            :icon="$vs.rtl ? 'ChevronRightIcon' : 'ChevronLeftIcon'"
                            @click="updateMonth(-1)"
                            svgClasses="w-6 h-6 m-1"
                            class="cursor-pointer hover:bg-grey hover:text-white rounded-full w-8 h-8"
                            :title="'Previous ' + calendarView"
                          />
          
                          <feather-icon
                            :icon="$vs.rtl ? 'ChevronLeftIcon' : 'ChevronRightIcon'"
                            @click="updateMonth(1)"
                            svgClasses="w-6 h-6 m-1 "
                            class="cursor-pointer hover:bg-grey hover:text-white rounded-full w-8 h-8"
                            :title="'Next ' + calendarView"
                          />
                        </div> -->

              <span
                class="mx-3 text-xl font-medium whitespace-no-wrap month-text"
                >Treasury Calendar</span
              ><br /><br />
              <div
                class="flex justify-center items-center mr-3 mob-cap-tabs-fx"
              >
                <template v-for="(view, index) in calendarPages">
                  <vs-button
                    v-if="currentRoute === view.val"
                    :key="String(view.val) + 'filled'"
                    type="filled"
                    class="min-px-4 fsize13 lg:w-1/4 mob-h40 mob-c-pd border-radius-button"
                    :class="{
                      'border-l-0 rounded-l-none': index,
                      'rounded-r-none': calendarPages.length !== index + 1,
                    }"
                    @click="navigateTo(view.val)"
                    >{{ view.label }}</vs-button
                  >
                  <vs-button
                    v-else
                    :key="String(view.val) + 'border'"
                    type="border"
                    class="min-px-4 lg:w-1/4 mob-h40 mob-c-pd fsize13 border-radius-button"
                    :class="{
                      'border-l-0 rounded-l-none': index,
                      'rounded-r-none': calendarPages.length !== index + 1,
                    }"
                    @click="navigateTo(view.val)"
                    >{{ view.label }}</vs-button
                  >
                </template>
              </div>
            </div>

            <div id="div-with-loading" class="vs-con-loading__container"></div>
          </template>
          <!-- <template slot="month">
            <div class="flex items-center">
              <div class="flex justify-around">
                <feather-icon
                  :icon="$vs.rtl ? 'ChevronRightIcon' : 'ChevronLeftIcon'"
                  @click="updateMonth(-1)"
                  svgClasses="w-6 h-6 m-1"
                  class="cursor-pointer hover:bg-grey hover:text-white rounded-full h-8 w-8"
                  :title="'Previous Month'"
                />

                <feather-icon
                  :icon="$vs.rtl ? 'ChevronLeftIcon' : 'ChevronRightIcon'"
                  @click="updateMonth(1)"
                  svgClasses="w-6 h-6 m-1"
                  class="cursor-pointer hover:bg-grey hover:text-white rounded-full h-8 w-8"
                  :title="'Next Month'"
                />
              </div>
              <span
                class="mx-3 text-xl font-medium whitespace-no-wrap month-text"
                >{{ fullMonths[showDate.getMonth()] }}&nbsp;{{
                  showDate.getFullYear()
                }}</span
              ><br />
            </div>
          </template> -->
        </CalendarHeader>
      </div>
      <div class="mob-scroll-x">
        <div class="md:w-full lg:w-full mob-width97rem">
          <div class="mob-borderbottom-r">
            <div class="flex">
              <div class="cust-w-8 text-center mob-mm-fixi border-all h-123">
                <div class="mt-3">
                  <div class="flex items-center justify-around">
                    <feather-icon
                      :icon="$vs.rtl ? 'ChevronRightIcon' : 'ChevronLeftIcon'"
                      @click.stop="updateFortnight(-1)"
                      svgClasses="w-5 h-5 m-1"
                      :title="'Previous Fortnight'"
                      class="cursor-pointer bg-primary text-white rounded-full h-5 w-5"
                    />
                    <h1
                      class="font-semibold text-3xl fontfamily-sans-serif cust-text-blue"
                    >
                      {{ months[showDate.getMonth()] }}
                    </h1>

                    <feather-icon
                      :icon="$vs.rtl ? 'ChevronLeftIcon' : 'ChevronRightIcon'"
                      @click.stop="updateFortnight(1)"
                      svgClasses="w-5 h-5 m-1"
                      class="cursor-pointer bg-primary text-white rounded-full h-5 w-5"
                      :title="'Next Fortnight'"
                    />
                  </div>
                  <h2
                    class="text-primary font-semibold text-2xl fontfamily-sans-serif"
                  >
                    {{ monthPosition }}/2
                  </h2>
                </div>
              </div>
              <div class="cust-w-92 bg-grid-color-secondary mob-ml8-rem">
                <div class="flex pt-1 border-t-b-r">
                  <div class="lg:w-3/12 mob-w28 bg-grid-color">
                    <div class="heading-part cust-h100">
                      <h5
                        class="bg-calendar-gradient font-bold mx-1 mob-fsize13 fontfamily-sans-serif rounded-lg p-5 text-center text-white"
                      >
                        Money Market
                      </h5>
                      <div class="flex">
                        <div class="lg:w-3/5 mob-w50 md:w-3/5">
                          <div
                            class="flex justify-center items-center bg-primary-trans cust-h40 fontfamily-sans-serif m-1 rounded-lg p-2"
                          >
                            <h5 class="fsize13 text-black">Inflow</h5>
                          </div>
                        </div>
                        <div class="lg:w-1/5 mob-w25">
                          <div
                            class="flex justify-center items-center p-1 bg-primary-trans m-1 rounded-lg cust-h40"
                          >
                            <h5
                              class="text-black fsize13 fontfamily-sans-serif"
                            >
                              Amount
                              <p
                                class="fsize9 fontfamily-sans-serif text-center text-black"
                              >
                                (₹ Crore)
                              </p>
                            </h5>
                          </div>
                        </div>
                        <!-- <div class="mob-w25 lg:w-1/5">
                          <div
                            class="flex justify-center items-center bg-primary-trans cust-h40 fontfamily-sans-serif m-1 rounded-lg p-2"
                          >
                            <h5 class="fsize13 text-black">Outflow</h5>
                          </div>
                        </div> -->
                      </div>
                    </div>
                  </div>
                  <div class="lg:w-1/6 mob-w20 bg-grid-color-secondary">
                    <div class="heading-part cust-h100">
                      <div class="mr-1">
                        <h6
                          class="bg-calendar-gradient font-bold mob-fsize13 fontfamily-sans-serif pt-3 pb-3 rounded-lg text-center text-white"
                        >
                          International <br />
                          Data
                        </h6>
                        <h5
                          class="bg-primary-trans fontfamily-sans-serif text-xs m-1 rounded-lg cust-h40 text-center"
                        ></h5>
                      </div>
                    </div>
                  </div>
                  <div class="lg:w-7/12 mob-w52 bg-grid-color-secondary">
                    <div class="heading-part cust-h100">
                      <h6
                        class="bg-calendar-gradient mob-fsize13 font-bold fontfamily-sans-serif mr-1 rounded-lg market-h-pd text-center text-white"
                      >
                        Market Last Year
                      </h6>
                      <div class="flex">
                        <div class="cust-w27 mob-mm-w21">
                          <h5
                            class="bg-primary-trans fsize13 text-black fontfamily-sans-serif m-1 rounded-lg cust-p-t-b text-center"
                          >
                            Currency
                            <span class="fsize11 fontfamily-sans-serif"
                              >(Against USD)</span
                            >
                          </h5>
                          <div class="flex justify-between ml-1 mr-1">
                            <div
                              class="mr-1 bg-primary-trans text-center rounded-lg xm-chips-w flex justify-center items-center"
                            >
                              <span
                                class="fsize9 fontfamily-sans-serif text-black mx-auto"
                                >EUR</span
                              >
                            </div>
                            <div
                              class="mr-1 bg-primary-trans text-center rounded-lg xm-chips-w flex justify-center items-center"
                            >
                              <span
                                class="fsize9 fontfamily-sans-serif text-black mx-auto"
                                >JPY</span
                              >
                            </div>
                            <div
                              class="mr-1 bg-primary-trans text-center rounded-lg xm-chips-w flex justify-center items-center"
                            >
                              <span
                                class="fsize9 fontfamily-sans-serif text-black mx-auto"
                                >GBP</span
                              >
                            </div>
                            <div
                              class="mr-1 bg-primary-trans text-center rounded-lg xm-chips-w flex justify-center items-center"
                            >
                              <span
                                class="fsize9 fontfamily-sans-serif text-black mx-auto"
                                >INR</span
                              >
                            </div>
                          </div>
                        </div>

                        <div class="cust-w-40 mob-mm-w31">
                          <h5
                            class="bg-primary-trans fsize13 text-black fontfamily-sans-serif m-1 rounded-lg cust-p-t-b text-center"
                          >
                            Interest Rates %
                          </h5>
                          <div class="flex justify-between mr-1">
                            <div
                              class="mr-1 bg-primary-trans items-center flex text-center rounded-lg xm-chips-wh"
                            >
                              <p
                                class="fsize9 fontfamily-sans-serif text-black mx-auto"
                              >
                                NSE MIBOR
                              </p>
                              <!-- <p class="fsize9 fontfamily-sans-serif text-black mx-auto">
                              MIBOR
                            </p> -->
                            </div>
                            <div
                              class="mr-1 items-center flex bg-primary-trans text-center rounded-lg xm-chips-wh"
                            >
                              <p
                                class="fsize9 fontfamily-sans-serif text-black mx-auto"
                              >
                                G-Sec 10-Yr
                              </p>
                              <!-- <p class="fsize9 fontfamily-sans-serif text-black mx-auto">
                        
                            </p> -->
                            </div>
                            <div
                              class="mr-1 items-center flex bg-primary-trans text-center rounded-lg xm-chips-wh"
                            >
                              <p
                                class="fsize9 fontfamily-sans-serif text-black mx-auto"
                              >
                                OIS 5-Yr
                              </p>
                              <!-- <p class="fsize9 fontfamily-sans-serif text-black mx-auto">
                            
                            </p> -->
                            </div>
                            <div
                              class="mr-1 items-center flex bg-primary-trans text-center rounded-lg xm-chips-wh"
                            >
                              <p
                                class="fsize9 fontfamily-sans-serif text-black mx-auto"
                              >
                                Corp Bond AAA 5-Yr
                              </p>
                              <!-- <p class="fsize9 fontfamily-sans-serif text-black mx-auto">
                              AAAA 5-Yr
                            </p> -->
                            </div>
                            <div
                              class="mr-1 items-center flex bg-primary-trans text-center rounded-lg xm-chips-wh"
                            >
                              <p
                                class="fsize9 fontfamily-sans-serif text-black mx-auto"
                              >
                                1-Yr INR fwd yield(%)
                              </p>
                              <!-- <p class="fsize9 fontfamily-sans-serif text-black mx-auto">
                              INR fwd (%)
                            </p> -->
                            </div>
                            <div
                              class="mr-1 items-center flex bg-primary-trans text-center rounded-lg xm-chips-wh"
                            >
                              <p
                                class="fsize9 fontfamily-sans-serif text-black mx-auto"
                              >
                                Overnight SOFR %
                              </p>
                              <!-- <p class="fsize9 fontfamily-sans-serif text-black mx-auto">
                              6-months
                            </p> -->
                            </div>
                          </div>
                        </div>
                        <div class="lg:w-1/3">
                          <h5
                            class="bg-primary-trans text-black fontfamily-sans-serif fsize13 m-1 rounded-lg cust-p-t-b text-center"
                          >
                            Others
                          </h5>
                          <div class="flex justify-between">
                            <div
                              class="flex items-center xm-chips-w ml-1 rounded-lg justify-center mr-1 bg-primary-trans"
                            >
                              <p
                                class="fsize9 fontfamily-sans-serif text-black mx-auto text-center"
                              >
                                Brent Oil($/bbl)
                              </p>
                            </div>

                            <div
                              class="flex items-center xm-chips-w ml-1 rounded-lg justify-center mr-1 bg-primary-trans"
                            >
                              <p
                                class="fsize9 fontfamily-sans-serif text-black mx-auto text-center"
                              >
                                Spot Gold ($/oz)
                              </p>
                            </div>

                            <!-- <div
                              class="flex items-center xm-chips-w ml-1 rounded-lg justify-center mr-1 bg-primary-trans"
                            >
                              <p
                                class="fsize9 fontfamily-sans-serif text-black mx-auto text-center"
                              >
                                CPI Inflation
                              </p>
                            </div> -->

                            <div
                              class="flex items-center xm-chips-w ml-1 rounded-lg justify-center mr-1 bg-primary-trans"
                            >
                              <p
                                class="fsize9 fontfamily-sans-serif text-black mx-auto text-center"
                              >
                                SENSEX
                              </p>
                            </div>

                            <div
                              class="flex items-center xm-chips-w ml-1 rounded-lg justify-center mr-1 bg-primary-trans"
                            >
                              <p
                                class="fsize9 fontfamily-sans-serif text-black mx-auto text-center"
                              >
                                System Liquidity (₹bn.)
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="min-overflow-y-hideauto min-h-screen90">
            <div class="border-b-blue mob-borderbottom-r">
              <div
                class="flex"
                v-for="(day, index) in datesToShow(showDate)"
                :key="'calandar-' + index"
              >
                <div
                  class="cust-w-8 body-section flex items-center fix-pos"
                  :class="{
                    'bg-blue-gradient text-white': !isWeekend(day),
                    'border-l-r-b': index !== datesToShow(showDate).length - 1,
                    'border-l-r-blue':
                      index === datesToShow(showDate).length - 1,
                  }"
                >
                  <div class="flex items-center justify-start ml-2">
                    <feather-icon
                      icon="PlusIcon"
                      class="cursor-pointer w-4 mr-2"
                      :class="{
                        'text-white': !isWeekend(day),
                        'text-calendar-blue': isWeekend(day),
                      }"
                      v-if="!dayNote(getDateObject(showDate, day))"
                      @click.stop="
                        toggleNotePopup(true, getDateObject(showDate, day))
                      "
                    ></feather-icon>
                    <img
                      src="@/Client/Kotak/img/pin.png"
                      class="cursor-pointer w-4"
                      v-if="dayNote(getDateObject(showDate, day))"
                      :title="dayNote(getDateObject(showDate, day)).note"
                      @click.stop="
                        handleNoteClick(getDateObject(showDate, day))
                      "
                    />
                  </div>
                  <div class="text-right w-full pr-2">
                    <h5
                      class="fsize18 fontfamily-sans-serif cursor-pointer"
                      :class="{ 'text-white': !isWeekend(day) }"
                      @click.stop="handleDayClick(getDateObject(showDate, day))"
                    >
                      {{
                        days[
                          dayOfDate(
                            showDate.getFullYear(),
                            showDate.getMonth(),
                            day
                          )
                        ]
                      }}
                      {{ day }}
                    </h5>
                    <span class="fsize12 fontfamily-sans-serif"
                      >{{
                        getCurrentDay(
                          showDate.getFullYear(),
                          showDate.getMonth(),
                          day
                        )
                      }}/{{
                        getLastDay(showDate.getFullYear(), months.length - 1)
                      }}</span
                    >
                  </div>
                </div>
                <div class="cust-w-92 bg-grid-color-secondary">
                  <div class="flex h-full border-b-r">
                    <!-- :class="{
                      'border-b-r': index !== datesToShow(showDate).length - 1,
                      'border-r-blue':
                        index === datesToShow(showDate).length - 1,
                    }" -->
                    <div class="lg:w-3/12 mob-w28 bg-grid-color">
                      <div
                        class="flex h-full"
                        :class="{
                          'bg-calendar-fortnight': fortnightlyHighlight(
                            new Date(
                              showDate.getFullYear(),
                              showDate.getMonth(),
                              day
                            )
                          ),
                        }"
                      >
                        <div class="lg:w-3/5 mob-w50 border-r">
                          <div
                            class="items-center h-full justify-start md:pl-2 lg:pl-2 flex"
                          >
                            <div class="grid items-center justify-start">
                              <p
                                class="fsize10 pl-2 fontfamily-sans-serif"
                                v-for="(item, index) in moneyMarket(
                                  getDate(
                                    showDate.getFullYear(),
                                    showDate.getMonth(),
                                    day
                                  )
                                )"
                                :key="'mm-in-des-' + index"
                                :class="{
                                  'text-primary': highlightData(
                                    getDate(
                                      showDate.getFullYear(),
                                      showDate.getMonth(),
                                      day
                                    ),
                                    item.inflow_description,
                                    'moneymarket'
                                  ),
                                  'text-black': !highlightData(
                                    getDate(
                                      showDate.getFullYear(),
                                      showDate.getMonth(),
                                      day
                                    ),
                                    item.inflow_description,
                                    'moneymarket'
                                  ),
                                }"
                              >
                                {{ item.inflow_description }}
                              </p>
                            </div>
                          </div>
                        </div>

                        <div class="mob-w25 lg:w-1/5 border-r">
                          <div
                            class="items-center justify-end flex h-full md:pr-2 lg:pr-2"
                          >
                            <div class="">
                              <p
                                class="fsize10 pr-2 text-right fontfamily-sans-serif"
                                v-for="(item, index) in moneyMarket(
                                  getDate(
                                    showDate.getFullYear(),
                                    showDate.getMonth(),
                                    day
                                  )
                                )"
                                :key="'mm-in-amt-' + index"
                                :class="{
                                  'text-primary': highlightData(
                                    getDate(
                                      showDate.getFullYear(),
                                      showDate.getMonth(),
                                      day
                                    ),
                                    item.inflow_description,
                                    'moneymarket'
                                  ),
                                  'text-black': !highlightData(
                                    getDate(
                                      showDate.getFullYear(),
                                      showDate.getMonth(),
                                      day
                                    ),
                                    item.inflow_description,
                                    'moneymarket'
                                  ),
                                }"
                              >
                                {{ roundoffdecimal(item.inflow_amount) }}
                              </p>
                            </div>
                          </div>
                        </div>

                        <div class="lg:w-1/5 mob-w25 border-r">
                          <div class="h-full flex justify-center items-center">
                            <span
                              class="pl-2 fontfamily-sans-serif fsize10"
                              v-for="(item, index) in moneyMarket(
                                getDate(
                                  showDate.getFullYear(),
                                  showDate.getMonth(),
                                  day
                                )
                              )"
                              :key="'mm-in-des-' + index"
                              :class="{
                                'text-primary': highlightData(
                                  getDate(
                                    showDate.getFullYear(),
                                    showDate.getMonth(),
                                    day
                                  ),
                                  item.inflow_description,
                                  'moneymarket'
                                ),
                                'text-black': !highlightData(
                                  getDate(
                                    showDate.getFullYear(),
                                    showDate.getMonth(),
                                    day
                                  ),
                                  item.inflow_description,
                                  'moneymarket'
                                ),
                              }"
                            >
                              {{ item.outflow_description }}</span
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="lg:w-1/6 mob-w20 bg-grid-color-secondary">
                      <div class="w-full cust-bg-grey border-r h-full">
                        <div class="items-center p-2 h-full flex">
                          <div class="">
                            <p
                              class="fsize9 fontfamily-sans-serif"
                              v-for="(item, index) in internationalData(
                                getDate(
                                  showDate.getFullYear(),
                                  showDate.getMonth(),
                                  day
                                )
                              )"
                              :key="'item-int-d-' + index"
                              :class="{
                                'text-primary': highlightData(
                                  getDate(
                                    showDate.getFullYear(),
                                    showDate.getMonth(),
                                    day
                                  ),
                                  item.event,
                                  'international'
                                ),
                                'text-black': !highlightData(
                                  getDate(
                                    showDate.getFullYear(),
                                    showDate.getMonth(),
                                    day
                                  ),
                                  item.event,
                                  'international'
                                ),
                              }"
                            >
                              {{ item.label }}
                              {{ item.event }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="lg:w-7/12 mob-w52 bg-grid-color-secondary">
                      <div class="flex h-full">
                        <div class="cust-w-7 border-r">
                          <div class="items-center justify-center flex h-full">
                            <span
                              class="fsize10 fontfamily-sans-serif text-black"
                              >{{
                                roundoffdecimal(
                                  marketLastYear(
                                    getDate(
                                      showDate.getFullYear(),
                                      showDate.getMonth(),
                                      day
                                    )
                                  ).market_last_year_currency_EUR,
                                  2,
                                  true
                                )
                              }}</span
                            >
                          </div>
                        </div>

                        <div class="cust-w-7 border-r">
                          <div class="items-center justify-center flex h-full">
                            <span
                              class="fsize10 fontfamily-sans-serif text-black"
                              >{{
                                roundoffdecimal(
                                  marketLastYear(
                                    getDate(
                                      showDate.getFullYear(),
                                      showDate.getMonth(),
                                      day
                                    )
                                  ).market_last_year_currency_JPY,
                                  2,
                                  true
                                )
                              }}</span
                            >
                          </div>
                        </div>

                        <div class="cust-w-7 border-r">
                          <div class="items-center justify-center flex h-full">
                            <span
                              class="fsize10 fontfamily-sans-serif text-black"
                              >{{
                                roundoffdecimal(
                                  marketLastYear(
                                    getDate(
                                      showDate.getFullYear(),
                                      showDate.getMonth(),
                                      day
                                    )
                                  ).market_last_year_currency_GBP,
                                  2,
                                  true
                                )
                              }}</span
                            >
                          </div>
                        </div>
                        <div class="cust-w-7 border-r">
                          <div class="items-center justify-center flex h-full">
                            <span
                              class="fsize10 fontfamily-sans-serif text-black"
                              >{{
                                roundoffdecimal(
                                  marketLastYear(
                                    getDate(
                                      showDate.getFullYear(),
                                      showDate.getMonth(),
                                      day
                                    )
                                  ).market_last_year_currency_INR,
                                  2,
                                  true
                                )
                              }}</span
                            >
                          </div>
                        </div>
                        <div class="cust-w-7 border-r">
                          <div class="items-center justify-center flex h-full">
                            <span
                              class="fsize10 fontfamily-sans-serif text-black"
                              >{{
                                roundoffdecimal(
                                  marketLastYear(
                                    getDate(
                                      showDate.getFullYear(),
                                      showDate.getMonth(),
                                      day
                                    )
                                  ).market_last_year_interest_rates_NSE_MIBOR,
                                  2,
                                  true
                                )
                              }}</span
                            >
                          </div>
                        </div>
                        <div class="cust-w-7 border-r">
                          <div class="items-center justify-center flex h-full">
                            <span
                              class="fsize10 fontfamily-sans-serif text-black"
                              >{{
                                roundoffdecimal(
                                  marketLastYear(
                                    getDate(
                                      showDate.getFullYear(),
                                      showDate.getMonth(),
                                      day
                                    )
                                  ).market_last_year_interest_rates_G_Sec_10_yr,
                                  2,
                                  true
                                )
                              }}</span
                            >
                          </div>
                        </div>
                        <div class="cust-w-7 border-r">
                          <div class="items-center justify-center flex h-full">
                            <span
                              class="fsize10 fontfamily-sans-serif text-black"
                              >{{
                                roundoffdecimal(
                                  marketLastYear(
                                    getDate(
                                      showDate.getFullYear(),
                                      showDate.getMonth(),
                                      day
                                    )
                                  ).market_last_year_interest_rates_OIS_10_yr,
                                  2,
                                  true
                                )
                              }}</span
                            >
                          </div>
                        </div>
                        <div class="cust-w-7 border-r">
                          <div class="items-center justify-center flex h-full">
                            <span
                              class="fsize10 fontfamily-sans-serif text-black"
                              >{{
                                roundoffdecimal(
                                  marketLastYear(
                                    getDate(
                                      showDate.getFullYear(),
                                      showDate.getMonth(),
                                      day
                                    )
                                  )
                                    .market_last_year_interest_rates_CorpBand_AAA_5_Yrr,
                                  2,
                                  true
                                )
                              }}</span
                            >
                          </div>
                        </div>
                        <div class="cust-w-7 border-r">
                          <div class="items-center justify-center flex h-full">
                            <span
                              class="fsize10 fontfamily-sans-serif text-black"
                              >{{
                                roundoffdecimal(
                                  marketLastYear(
                                    getDate(
                                      showDate.getFullYear(),
                                      showDate.getMonth(),
                                      day
                                    )
                                  )
                                    .market_last_year_interest_rates_1_Yr_INR_fwd,
                                  2,
                                  true
                                )
                              }}</span
                            >
                          </div>
                        </div>
                        <div class="cust-w-7 border-r">
                          <div class="items-center justify-center flex h-full">
                            <span
                              class="fsize10 fontfamily-sans-serif text-black"
                              >{{
                                roundoffdecimal(
                                  marketLastYear(
                                    getDate(
                                      showDate.getFullYear(),
                                      showDate.getMonth(),
                                      day
                                    )
                                  )
                                    .market_last_year_interest_rates_Overnight_SOFR,
                                  2,
                                  true
                                )
                              }}</span
                            >
                          </div>
                        </div>
                        <div class="cust-w-7 border-r">
                          <div class="items-center justify-center flex h-full">
                            <span
                              class="fsize10 fontfamily-sans-serif text-black"
                              >{{
                                roundoffdecimal(
                                  marketLastYear(
                                    getDate(
                                      showDate.getFullYear(),
                                      showDate.getMonth(),
                                      day
                                    )
                                  ).market_last_year_others_Brent_Oil_per_bbl,
                                  2,
                                  true
                                )
                              }}</span
                            >
                          </div>
                        </div>
                        <div class="cust-w-7 border-r">
                          <div class="items-center justify-center flex h-full">
                            <span
                              class="fsize10 fontfamily-sans-serif text-black"
                              >{{
                                roundoffdecimal(
                                  marketLastYear(
                                    getDate(
                                      showDate.getFullYear(),
                                      showDate.getMonth(),
                                      day
                                    )
                                  ).market_last_year_others_Spot_Gold_per_oz
                                )
                              }}</span
                            >
                          </div>
                        </div>
                        <div class="cust-w-7 border-r">
                          <div class="items-center justify-center flex h-full">
                            <span
                              class="fsize10 fontfamily-sans-serif text-black"
                              >{{
                                roundoffdecimal(
                                  marketLastYear(
                                    getDate(
                                      showDate.getFullYear(),
                                      showDate.getMonth(),
                                      day
                                    )
                                  ).market_last_year_others_CPI_Inflation,
                                  2,
                                  true
                                )
                              }}</span
                            >
                          </div>
                        </div>
                        <div class="cust-w-7 border-r">
                          <div class="items-center justify-center flex h-full">
                            <span
                              class="fsize10 fontfamily-sans-serif text-black"
                              >{{
                                roundoffdecimal(
                                  marketLastYear(
                                    getDate(
                                      showDate.getFullYear(),
                                      showDate.getMonth(),
                                      day
                                    )
                                  ).market_last_year_others_SENSEX
                                )
                              }}</span
                            >
                          </div>
                        </div>

                        <div class="cust-w-7 border-r">
                          <div class="items-center justify-center flex h-full">
                            <span
                              class="fsize10 fontfamily-sans-serif text-black"
                              >{{
                                roundoffdecimal(
                                  marketLastYear(
                                    getDate(
                                      showDate.getFullYear(),
                                      showDate.getMonth(),
                                      day
                                    )
                                  )
                                    .market_last_year_others_System_Liquidity_Deficit_Rs_bn
                                )
                              }}</span
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <MoneyMarketFooter :showDate="showDate" />
            <div class="px-2 pb-4">
              <div>
                <p class="fsize10 line-15">
                  <span class="font-semibold">Data Sources:</span>
                  1) Markets last year – Bloomberg and Reserve Bank of India. 2)
                  Holiday dates for the US, UK, Japan and the Euro region along
                  with the Central Bank meeting dates of these economies –
                  Bloomberg and the respective country’s official central bank
                  websites.
                </p>
              </div>

              <div class="">
                <ul class="mt-1">
                  <li class="fsize10 line-15">
                    <span class="font-semibold">Instructions:</span>
                    1) The calendar is designed to follow the flow of funds
                    in/out of the monetary system and incorporates all
                    outstanding government securities as of 31st December 2020.
                    2) Treasury Bill auction dates are indicated without the
                    notified amounts as the auction size can change in any
                    quarter and depending on the Central Government’s budgetary
                    requirement. 3) System liquidity = Outstanding net LAF +
                    outstanding MSF + outstanding net Term Repos of the RBI +
                    Standing Liquidity Facility. 4) A negative number implies
                    system liquidity deficit. 5) The G-sec redemptions are
                    subject to change in case of any debt buyback/switch. 6) For
                    updates on cash flows, please refer to our Weekly Money
                    Market reports.
                  </li>
                </ul>
              </div>

              <div class="mt-2">
                <p class="fsize8 line-15">
                  <span class="font-bold">Disclaimer:</span>
                  In the preparation of the material content on this page, we
                  have used information that is publicly available and is static
                  in nature. Information gathered & material used in this
                  document is from reliable sources.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="demo-alignment cust-popup-bg">
            <NotePopup
              :isActive="isNotePopupOpen"
              :title="notePopupTitle"
              @closePopup="toggleNotePopup"
            />
          </div>
          <div class="demo-alignment cust-popup-bg">
            <MoneyMarketDayView
              :isActive="isDayPopupOpen"
              @closePopup="toggleDayPopup"
              :params="dayParams"
              :fullscreen="isMobileView || !isNoteView"
              :noteView="isNoteView"
              :isLoggedIn="isLoggedIn"
            />
          </div>

          <!-- footer -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import calendarMixin from "@/Client/Kotak/mixins/calendarMixin";
import noteMixin from "@/Client/Kotak/mixins/noteMixin";

import highlight from "../utils/highligts";

import MoneyMarketFooter from "../components/MoneyMarketFooter";
import NotePopup from "../components/NotePopup";
import MoneyMarketDayView from "../components/MoneyMarketDayView";
import CalendarHeader from "../components/CalendarHeader";
import SelectMonth from "../components/SelectMonth";

import jwt from "jsonwebtoken";

import treasuryAnalyticsMixin from "@/Client/Kotak/mixins/treasuryAnalyticsMixin";

export default {
  mixins: [calendarMixin, noteMixin, treasuryAnalyticsMixin],
  components: {
    MoneyMarketFooter,
    NotePopup,
    MoneyMarketDayView,
    CalendarHeader,
    SelectMonth,
  },
  data() {
    return {
      calendarPages: [
        {
          label: "FX",
          val: "fx-calendar",
        },

        {
          label: "MM",
          val: "money-market-calendar",
        },
      ],
      days: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
      months: [
        "JAN",
        "FEB",
        "MAR",
        "APR",
        "MAY",
        "JUN",
        "JUL",
        "AUG",
        "SEP",
        "OCT",
        "NOV",
        "DEC",
      ],
      showDate: new Date(),
      token: {},
      isDayPopupOpen: false,
      dayParams: {},
      isNoteView: false,
      highlights: highlight,
      isLoggedIn: false,
    };
  },
  watch: {
    showDate() {
      this.getCalendarData();
    },
  },
  computed: {
    isWeekend() {
      return (day) => {
        return (
          this.dayOfDate(
            this.showDate.getFullYear(),
            this.showDate.getMonth(),
            day
          ) === 0 ||
          this.dayOfDate(
            this.showDate.getFullYear(),
            this.showDate.getMonth(),
            day
          ) === 6
        );
      };
    },
    ...mapState("calendar", {
      events: "moneyMarketEvents",
    }),
    moneyMarket() {
      return (date) => {
        return this.getDayData(date) ? this.getDayData(date).moneyMarket : [];
      };
    },
    internationalData() {
      return (date) => {
        return this.getDayData(date)
          ? this.getDayData(date).internationalData
          : [];
      };
    },
    marketLastYear() {
      return (date) => {
        return this.getDayData(date)
          ? this.getDayData(date).marketLastYear &&
            this.getDayData(date).marketLastYear.length
            ? this.getDayData(date).marketLastYear[0]
            : ""
          : [];
      };
    },
    fortnightlyHighlight() {
      return (date) => {
        return this.isOddWeek(date) && new Date(date).getDay() === 5;
      };
    },
    ...mapState("note", {
      notes: "mmnotes",
    }),
    monthPosition() {
      const day = new Date(this.showDate).getDate();
      let divisor = 2;
      if (this.showDate.getMonth() === 1) {
        // February Fix
        divisor = Math.floor(day / 15) ? 2 : 1;
      } else {
        divisor = day > 15 && Math.floor(day / 15) ? 2 : 1;
      }
      return divisor;
    },
    ...mapState("note", {
      notes: "notes",
    }),
    currentRoute() {
      return this.$route.name;
    },
  },
  methods: {
    navigateTo(route) {
      if (!route) return;
      this.$router.push({ name: route });
    },
    roundoffdecimal(value, point = 0, isfixed = false) {
      if (isNaN(value)) {
        return value;
      } else {
        let roundby = Math.pow(10, point);
        if (isfixed) {
          return (Math.round(value * roundby) / roundby).toFixed(2);
        }
        return Math.round(value * roundby) / roundby;
      }
    },
    getFirstDay(year, month) {
      return new Date(year, month).getDay();
    },
    getLastDay(year, month) {
      return this.daysIntoYear(
        new Date(year, month, this.daysInMonth(month, year))
      );
    },
    getCurrentDay(year, month, day) {
      return this.daysIntoYear(new Date(year, month, day));
    },

    getDate(year, month, day) {
      const date = new Date(year, month, day);
      date.setHours(0, -date.getTimezoneOffset(), 0, 0);
      return date.toISOString();
    },

    datesToShow(date) {
      const year = new Date(date).getFullYear();
      const month = new Date(date).getMonth();
      const day = new Date(date).getDate();
      let divisor = 1;
      if (month === 1) {
        // February Fix
        divisor = Math.floor(day / 15) ? 1 : 2;
      } else {
        divisor = day > 15 && Math.floor(day / 15) ? 1 : 2;
      }

      const lastDay = (32 - new Date(year, month, 32).getDate()) / divisor;
      const firstDay =
        divisor === 1
          ? Math.floor((32 - new Date(year, month, 32).getDate()) / 2 + 1)
          : 1;

      const array = [];
      for (let index = firstDay; index <= lastDay; index++) {
        array.push(index);
      }

      return array;
    },

    dayOfDate(year, month, day) {
      return new Date(year, month, day).getDay();
    },

    daysIntoYear(date) {
      return (
        (Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()) -
          Date.UTC(date.getFullYear(), 0, 0)) /
        24 /
        60 /
        60 /
        1000
      );
    },
    updateFortnight(inc) {
      let val = this.datesToShow(this.showDate).length;
      if (inc > 0) {
        val = this.datesToShow(this.showDate).length;
      } else {
        val = -this.datesToShow(this.showDate).length;
      }

      this.showDate = new Date(
        this.showDate.getFullYear(),
        this.showDate.getMonth(),
        this.showDate.getDate() + val
      );
    },

    updateMonth(val) {
      this.showDate = new Date(
        this.showDate.getFullYear(),
        this.showDate.getMonth() + val,
        1
      );
    },

    async getCalendarData() {
      const year = this.showDate.getFullYear();
      const month = this.showDate.getMonth();
      const payload = {
        startDate: new Date(year, month, 1),
        endDate: new Date(year, month + 1, 0 + 1),
      };

      const isLoggedIn = await this.checkLogin();

      const notePayload = {
        ...payload,
        userID: isLoggedIn ? this.token.uuid : "",
      };

      try {
        this.$vs.loading();

        const data = await this.$store.dispatch(
          "calendar/getMoneyMarket",
          payload
        );
        this.setCalendarData(data);

        if (isLoggedIn) {
          await this.$store.dispatch("note/getNote", notePayload);
        }

        this.$vs.loading.close();
      } catch (error) {
        if (error) this.$vs.loading.close();
      }
    },
    getDayData(date) {
      if (!this.events.length) return false;
      const index = this.events.findIndex((x) => x._id === date);
      return this.events[index];
    },
    setCalendarData(data) {
      if (!data.length) return;
      const result = [];
      data.forEach((element) => {
        const index = this.events.findIndex((x) => x._id === element._id);
        if (index === -1) {
          result.push(element);
        }
      });
      this.$store.commit("calendar/SET_MONEY_MARKET_EVENTS", result);
    },
    handleNoteClick(date) {
      const selectedDate = new Date(date);
      const params = {
        date: date,
        year: selectedDate.getFullYear(),
        month: selectedDate.getMonth(),
        day: selectedDate.getDate(),
        note: this.getNote(date),
      };
      this.dayParams = params;
      this.isNoteView = true;
      this.toggleDayPopup(true);
    },
    handleDayClick(date) {
      const selectedDate = new Date(date);
      const params = {
        date: date,
        year: selectedDate.getFullYear(),
        month: selectedDate.getMonth(),
        day: selectedDate.getDate(),
        note: this.getNote(date),
      };
      this.dayParams = params;
      this.isNoteView = false;
      this.toggleDayPopup(true);
    },
    toggleDayPopup(val = false) {
      this.isDayPopupOpen = val;
      if (!val) {
        this.dayParams = {};
        this.isNoteView = false;
      }
    },
    highlightData(date, event, calendartype) {
      if (calendartype == "international") {
        return !!this.getDayData(date).internationalData.find(
          (data) => data.event == event && data.is_highlighted == "Yes"
        );
      }
      if (calendartype == "moneymarket") {
        return !!this.getDayData(date).moneyMarket.find(
          (data) =>
            data.inflow_description == event && data.is_highlighted == "Yes"
        );
      }
    },
    setMonthFromSelect(date) {
      this.showDate = date;
    },
  },
  mounted() {
    this.getCalendarData();
    this.trackPage("calendar/mm");
  },
  async created() {
    this.isLoggedIn = await this.checkLogin();
    if (this.isLoggedIn) {
      let newtoken = localStorage.getItem("token");
      this.token = jwt.decode(newtoken);
      this.addViewedPromotionLog("money-market-calendar");
      window.onbeforeunload = window.addEventListener(
        "beforeunload",
        this.updateViewedPromotionLog
      );
    }
  },
};
</script>

<style>
.mm-body {
  flex-grow: 1;
  box-sizing: border-box;
  line-height: 1em;
  font-size: 1em;
  min-height: 100%;
  max-height: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
}
</style>

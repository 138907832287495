export default {
  methods: {
    async getData(periodStart, periodEnd) {
      if (!periodStart && !periodEnd) return;
      const startDate = new Date(periodStart);
      const endDate = new Date(periodEnd);

      const payload = {
        startDate: this.formatDate(startDate),
        endDate: this.formatDate(endDate)
      };

      try {
        this.openLoadingInDiv();

        const data = await this.$store.dispatch("calendar/getFX", payload);

        this.setEvents(data);

        this.closeLoadingInDiv();
      } catch (error) {
        if (error) this.closeLoadingInDiv();
        this.isError = true;
      }
    },
    formatDate(date) {
      return date.toISOString();
    },
    openLoadingInDiv() {
      this.isLoading = true;
      const element = document.getElementById("div-with-loading");
      if (element)
        this.$vs.loading({
          container: "#div-with-loading",
          scale: 0.6,
          type: "sound"
        });
    },
    closeLoadingInDiv() {
      this.isLoading = false;
      this.$vs.loading.close("#div-with-loading > .con-vs-loading");
    },
    setEvents(payload) {
      const result = [];
      payload.forEach((element, index) => {
        //If event does not exist in the calendar
        let colorClass = `event-calendar-blue`;
        if (element.isHoliday) {
          // If event is a holiday
          // Get index of existing holiday on the same date
          const holidayIndex = result.findIndex(
            x =>
              x.title.includes(element.title) &&
              x.startDate === element.startDate
          );
          //If holiday exists on same day prefix label to exisiting title
          if (holidayIndex !== -1) {
            result[holidayIndex].title = result[holidayIndex].title.replace(
              '<span class="text-primary uppercase">',
              ""
            );
            result[
              holidayIndex
            ].title = `<span class="text-primary uppercase"><span class="font-semibold">${element.label}</span> / ${result[holidayIndex].title}`;
          } else {
            //If holiday does not exist on same day prefix label to title and add google search link of holiday
            element.title = `<span class="text-primary uppercase"><span class="font-semibold">${element.label}</span> - <a href="https://www.google.com/search?q=${element.title}" target="_blank">${element.title}</a></span>`;
            result.unshift({
              ...element,
              classes: `${colorClass} mr-1 text-xs fx-event`
            });
          }
        } else {
          //Find index of events with same label and date
          const labelIndex = result.findIndex(
            x =>
              x.label === element.label &&
              x.startDate === element.startDate &&
              !element.isHoliday
          );
          //If label is found suffix title to existing title
          if (labelIndex !== -1) {
            result[labelIndex].title += `, ${element.title}`;
          } else {
            //If label is not found prefix label in title
            element.title = `<span class="font-semibold">${element.label}</span> - ${element.title}`;
            result.push({
              ...element,
              classes: `${colorClass} mr-1 text-xs fx-event`
            });
          }
        }
        if (index === payload.length - 1) {
          //On last iteration send modified event array for grouping
          this.setFilteredEvents(result);
        }
      });
    },
    setFilteredEvents(result) {
      //Group events of same date into holiday and other
      const filtered = [];
      result.forEach((element, index) => {
        //Find index of elements based on date and holiday status
        const dateIndex = filtered.findIndex(
          x =>
            x.startDate === element.startDate &&
            x.isHoliday === element.isHoliday
        );
        //If index is found suffix title and add a break
        if (dateIndex !== -1) {
          filtered[dateIndex].title += `${element.title} <br>`;
        } else {
          //If index is not found add break to title
          element.title = `${element.title}<br>`;
          filtered.push(element);
        }
        if (index === result.length - 1) {
          //On last iteration send modified array for further grouping
          this.filterFilteredEvents(filtered);
        }
      });
    },
    filterFilteredEvents(result) {
      //Group events based on date
      const filter = [];

      result.forEach((element, index) => {
        //Find if event already exists in calendar

        //If event does not already exist in calendar
        const dateIndex = filter.findIndex(
          x => x.startDate === element.startDate
        );
        if (dateIndex !== -1) {
          //If event of same date is found
          filter[dateIndex].title += `${element.title} <br>`;
        } else {
          //If event of same date is not found
          element.title = `${element.title}<br>`;
          filter.push(element);
        }

        if (index === result.length - 1) {
          //Add Events to calendar for display
          filter.forEach(element => {
            if (element.isBankHoliday) {
              let className = `.d${element.startDate.slice(0, 10)}`;
              const style = document.createElement("style");
              style.innerHTML = `${className} .cv-day-number { color: rgba(var(--vs-danger), 1) !important }`;
              document.head.appendChild(style);
            }
          });

          this.fxEvents = filter[0];
        }
      });
    }
  }
};

<template>
  <div
    class="lg:flex md:flex mob-flex-575 flex-wrap justify-between pt-4 pb-2 "
  >
    <div class="lg:w-1/2 md:w-1/2  flex-wrap mob-w50 flex mob-footer-tb">
      <Calendar
        class="mx-2"
        v-for="(n, i) in bottomCalendarMonths"
        :key="n + '-' + i"
        :from-date="getMonth(n)"
        :min-date="getMonth(n)"
        :max-date="getLastDate(n)"
        :attributes="getDateAttributes(n)"
      >
        <div slot="header-title" slot-scope="page" @click.stop="doNothing">
          {{ page.monthLabel }} {{ page.yearLabel }}
        </div>
      </Calendar>
    </div>

    <div class="right-sec lg:w-1/2 md:w-1/2  mob-w50 md:px-4 lg:px-4 px-2">
      <!-- <div class="w-full mt-4">
        <img src="@/Client/Kotak/img/kotak-logo.png" class="w-full h-full" />
      </div> -->
      <div class="cust-w90 flex flex-col-reverse items-end m-3">
        <div class="mt-4">
          <p class="text-black font-medium text-right mob-mb-10px line-h20">
            "{{ quotes[number].quote }}"
          </p>
          <p class="text-right text-primary font-medium mt-2">
            - {{ quotes[number].author }}
          </p>
        </div>

        <div class=" w-full h-18">
          <div class="flex items-center">
            <span>
              <feather-icon
                icon="PlusIcon"
                class="cursor-pointer w-5 text-gray"
              ></feather-icon
            ></span>
            <!-- <span>/</span>
            <span>
              <img
                src="@/Client/Kotak/img/pin.png"
                class="cursor-pointer mb-2 w-4"
              />-->
            <span class="fsize11 text-calendar-blue ml-1"> Add Note</span>
          </div>
          <div>
            <span
              v-for="(label, index) in otherCalendarLabels"
              :key="index"
              class="fsize11  h-4"
            >
              <!-- <div
              class="h-3 w-3 inline-block rounded-full mr-2"
              :class="'bg-' + label.color"
            ></div> -->

              <span :class="'text-' + label.color"
                >{{ label.text
                }}<span v-if="index !== calendarLabels.length - 1" class="mr-1"
                  >.</span
                ></span
              >
            </span>
          </div>
          <div class="flex items-center">
            <span
              class="rounded-lg inline-block bg-calendar-fortnight h-3 w-3"
            ></span>
            <span class="fsize11 text-calendar-blue ml-2"
              >Reporting friday</span
            >
          </div>
          <div class="flex flex-wrap">
            <span
              v-for="(label, index) in calendarLabels"
              :key="index"
              class="fsize11  h-4"
            >
              <!-- <div
              class="h-3 w-3 inline-block rounded-full mr-2"
              :class="'bg-' + label.color"
            ></div> -->

              <span :class="'text-' + label.color"
                >{{ label.text
                }}<span v-if="index !== calendarLabels.length - 1" class="mr-1"
                  >,</span
                ></span
              >
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Calendar from "v-calendar/lib/components/calendar.umd";

import calendarMixin from "@/Client/Kotak/mixins/calendarMixin";
import quotes from "../utils/quotes";
import PollView from "../../../../../Core/Views/pages/IndividualPost/components/PollView.vue";

export default {
  mixins: [calendarMixin],
  props: {
    showDate: {
      type: Date,
      default: new Date()
    }
  },
  components: {
    Calendar,
    PollView
  },
  data() {
    return {
      date: new Date(),
      quotes: quotes,
      randomNumber: 0,
      bottomCalendarMonths: [-1, 1, 2]
    };
  },
  watch: {
    showDate() {
      this.setRandomNumber();
    }
  },
  computed: {
    number: {
      set(val) {
        this.randomNumber = val;
      },
      get() {
        return this.randomNumber;
      }
    },
    calendarLabels() {
      return this.$store.state.calendar.mmEventLabels;
    },
    otherCalendarLabels() {
      return this.$store.state.calendar.otherMMEventLabels;
    }
  },
  methods: {
    getMonth(increment) {
      const year = this.showDate.getFullYear();
      const month = this.showDate.getMonth() + increment;
      return new Date(year, month, 1);
    },
    getLastDate(increment) {
      const year = this.showDate.getFullYear();
      const month = this.showDate.getMonth() + increment;
      return new Date(year, month, this.daysInMonth(month, year));
    },
    getDateAttributes(increment) {
      const attributes = [
        {
          dates: {
            start: this.getMonth(increment),
            end: this.getLastDate(increment),
            weekdays: [1]
          },
          highlight: {
            contentClass: "text-danger"
          }
        }
      ];
      return attributes;
    },
    setRandomNumber() {
      this.randomNumber = this.getRandomNumber();
    },
    getRandomNumber() {
      return Math.floor(Math.random() * this.quotes.length);
    },
    doNothing() {
      return false;
    }
  }
};
</script>

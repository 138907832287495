export default {
  data() {
    return {
      isNotePopupOpen: false,
      notePopupTitle: ""
    };
  },
  computed: {
    dayNote() {
      return date => {
        return this.getNote(date) && this.notes.length
          ? this.getNote(date)
          : false;
      };
    }
  },
  methods: {
    getNote(date) {
      const now = this.getCurrentDate(date);
      if (this.notes.length) {
        const index = this.notes.findIndex(x => x.date === now);

        return this.notes[index];
      } else {
        return [];
      }
    },

    async toggleNotePopup(val = false, day = null) {
      const isLoggedIn = await this.checkLogin();
      if (isLoggedIn) {
        this.isNotePopupOpen = val;
        this.notePopupTitle = day;
      } else {
        const params = {};
        if (this.$route.name === "fx-calendar") {
          params.toFXCalendar = true;
        }

        if (this.$route.name === "money-market-calendar") {
          params.toMMCalendar = true;
        }
        this.$router.push({
          name: "page-kotak-login",
          params: params
        });
      }
    }
  }
};
